import React, { Fragment, useContext, useEffect } from "react"
import classNames from "classnames"

import {
  accumulatePrice,
  generateDeliveryFee,
  accumulateVat,
} from "../Epharmacy/services/computations"
import styles from "./utils/cart.module.scss"
import { AppContext } from "context/AppContext"

const OrderSubtotalSection = ({ medicines, couponDiscount, addressType }) => {
  const { dispatch, state } = useContext(AppContext)
  let total = accumulatePrice(medicines)
  let discount = parseFloat(couponDiscount).toFixed(2)
  let subTotal = Math.abs(total - couponDiscount).toFixed(2)

  // subTotal =
  //   parseFloat(state.epharmacy.coupon.percentOff) === 100 ? 0 : subTotal

  let orderDetails = {
    total: total.toFixed(2),
    discount: discount,
    subTotal: subTotal,
  }

  useEffect(() => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          orderDetails,
        },
      },
    })
  }, [medicines])

  return (
    <Fragment>
      <hr
        className={classNames("has-background-light", styles["row__divider"])}
      />

      <div className="columns is-size-6 is-mobile">
        <div className="column has-text-right">
          Total Payment Due (For Salary Deduction):
        </div>
        <div className="column is-5 has-text-weight-bold has-text-right">
          <span className="mr-1">Php {orderDetails.subTotal}</span>
        </div>
      </div>
      <p className="is-size-7 has-text-left px-1 has-text-grey">
        Total amount will be verified by our pharmacist via email. Service fee
        will be applied upon checkout.
      </p>
    </Fragment>
  )
}

export default OrderSubtotalSection
