import React from "react"
import Cart from "../components/Epharmacy/Cart"

import { initialState } from "./AppContext"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "./services/context"
import { isObjectEmpty } from "../services/general"
import layoutStyles from "../components/Layout/utils/layout.module.scss"

const SAVE_AGREE_MECHANICS = "SAVE_AGREE_MECHANICS"
const SAVE_AGREE_CONSENT = "SAVE_AGREE_CONSENT"
const SAVE_MEDICINES = "SAVE_MEDICINES"
const SAVE_ORDERING_FOR = "SAVE_ORDERING_FOR"
const SAVE_DOCUMENTS = "SAVE_DOCUMENTS"
const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS"
const SAVE_DELIVERY_DETAILS = "SAVE_DELIVERY_DETAILS"
const RESET_DETAILS = "RESET_DETAILS"
const SHOW_MODAL = "SHOW_MODAL"
const HIDE_MODAL = "HIDE_MODAL"
const SHOW_CART = "SHOW_CART"
const SAVE_COUPON = "SAVE_COUPON"
const REMOVE_COUPON = "REMOVE_COUPON"
const SHOW_TOAST = "SHOW_TOAST"
const HIDE_TOAST = "HIDE_TOAST"
const SHOW_DISCLAIMER = "SHOW_DISCLAIMER"
const HIDE_DISCLAIMER = "HIDE_DISCLAIMER"
const SAVE_EMPLOYEE_FRONT_ID = "SAVE_EMPLOYEE_FRONT_ID"
const SAVE_EMPLOYEE_BACK_ID = "SAVE_EMPLOYEE_BACK_ID"
const SAVE_CONTEXT_TO_SESSION = "SAVE_CONTEXT_TO_SESSION"
const GET_CONTEXT_FROM_SESSION = "GET_CONTEXT_FROM_SESSION"
const REMOVE_CONTEXT_FROM_SESSION = "REMOVE_CONTEXT_FROM_SESSION"
const SAVE_HEALTH_SURVEY = "SAVE_HEALTH_SURVEY"
const SAVE_DEPENDENT_DETAILS = "SAVE_DEPENDENT_DETAILS"
const SAVE_ADDRESS_DETAILS = "SAVE_ADDRESS_DETAILS"
const SAVE_DELIVERY_FEE = "SAVE_DELIVERY_FEE"



export let AppReducer = (state, action) => {
  switch (action.type) {
    case SAVE_AGREE_MECHANICS:
      return {
        ...state,
        flexmed: { ...state.flexmed, agreeToMechanics: action.payload },
      }

    case SAVE_AGREE_CONSENT:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, agreeToConsent: action.payload },
      }

    case SAVE_MEDICINES:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: action.payload },
      }

    case SAVE_ORDERING_FOR:
      return {
        ...state,
        orderingFor: action.payload,
      }

    case SAVE_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      }
    case SAVE_EMPLOYEE_FRONT_ID:
      return {
        ...state,
        employeeId: { ...state.employeeId, front: action.payload },
      }
    case SAVE_EMPLOYEE_BACK_ID:
      return {
        ...state,
        employeeId: { ...state.employeeId, back: action.payload },
      }
    case SAVE_PERSONAL_DETAILS:
      return {
        ...state,
        flexmed: { ...state.flexmed, ...action.payload },
      }

    case SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }

    case SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case SHOW_CART:
      return {
        ...state,
        ...action?.payload,
        modal: {
          ...state.modal,
          isCard: true,
          isFullheight: true,
          heading: "My Cart",
          headingClass: "has-text-weight-bold has-text-primary",
          content: (
            <div className={layoutStyles["navbar__cart"]}>
              <Cart />
            </div>
          ),
          background: { color: "white", opacity: 100 },
          isActive: true,
        },
      }

    case SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case SHOW_DISCLAIMER:
      return { ...state, disclaimer: { ...initialState.disclaimer } }

    case HIDE_DISCLAIMER:
      return { ...state, disclaimer: { ...action.payload, isActive: false } }

    case SAVE_COUPON:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, coupon: {...action.payload} },
      }

    case REMOVE_COUPON:
      return { ...state, epharmacy: { ...state.epharmacy, coupon: null } }

    case RESET_DETAILS:
      return { ...initialState }

    case SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
      }

    case REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }

    case SAVE_HEALTH_SURVEY:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, healthSurvey: action.payload },
      }
    case SAVE_DEPENDENT_DETAILS:
      return {
        ...state,
        epharmacy: {...state.epharmacy, 
          ...action.payload
        }
      }
    case SAVE_ADDRESS_DETAILS:
        return {
          ...state,
          epharmacy: {...state.epharmacy, 
          deliveryAddress: {...state.epharmacy.deliveryAddress, ...action.payload}
          }
        }
    case SAVE_DELIVERY_FEE:
        return{
          ...state,
          epharmacy: {...state.epharmacy,
          orderDetails: {...state.epharmacy.orderDetails, deliveryFee:action.payload}
        }
      }
    default:
      return
  }
}
