import React, { useContext } from "react"
import classNames from "classnames"

import DocumentsCardRow from "./DocumentsCard/DocumentsCardRow"
import CollapsibleCard from "../Elements/CollapsibleCard"
import Button from "../Elements/Button"

import { AppContext } from "../../context/AppContext"
import styles from "./utils/cart.module.scss"
import { navigate } from "gatsby"

const DocumentsCard = ({ title }) => {
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const handleDeleteDocument = async (index) => {
    const documentsList = [...documents]
    documentsList.splice(index, 1)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [...documentsList],
      },
    })
  }

  const handleRedirectToUpload = () => {
    dispatch(
      {
        type: "HIDE_MODAL",
      },
      navigate("/epharmacy/upload")
    )
  }

  return (
    <CollapsibleCard
      title={title || `Documents Uploaded (${documents.length})`}
      color="white"
      errorMessage={
        documents.length === 0 && {
          message: "You have no prescriptions in your list.<br/>",
          onClick: handleRedirectToUpload,
        }
      }
      isOpen={true}
    >
      <div className={classNames("mt-1", styles["cart"])}>
        {documents.map((file, index) => (
          <DocumentsCardRow
            key={index}
            fileName={file.name}
            oldFileName={file.oldname}
            index={index}
            handleDeleteDocument={handleDeleteDocument}
            file={file}
          />
        ))}
      </div>
      {/* <p className="has-text-left">
        <Button
          className="is-text has-text-primary"
          onClick={handleRedirectToUpload}
        >
          + add another prescription
        </Button>
      </p> */}
    </CollapsibleCard>
  )
}

export default DocumentsCard
