import React, { useReducer } from "react"
import { AppReducer } from "./AppReducer"
import Modal from "../components/Elements/Modal"
import Toast from "../components/Layout/Toast"
import Disclaimer from "../components/Layout/Disclaimer"

export const initialState = {
  documents: [],
  employeeId: {
    front: {},
    back: {},
  },
  orderingFor: null,
  epharmacy: {
    agreeToMechanics: false,
    agreeToConsent: [],
    medicines: [],
    hasPartnerRequest: false,
    coupon: {},
    addressType: "Home",
    recipientMobileNumber: "",
    deliveryAddress: {
      streetAddress: "",
      province: {
        label: "",
        value: "",
      },
      city: {
        label: "",
        value: "",
      },
      barangay: {
        label: "",
        value: "",
      },
      notes: "",
    },
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    personalEmail: "",
    designation: "",
    employeeIDNumber: "",
    civilStatus: "",
    beneficiaryFirstName: "",
    beneficiaryLastName: "",
    beneficiaryType: {
      value: "",
    },
    beneficiaryOthers: "",
    birthday: {
      month: "",
      date: "",
      year: "",
    },
    orderNotes: "",
    orderDetails: {
      total: 0,
      discount: 0,
      deliveryFee: 0,
      subTotal: 0,
    },
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  disclaimer: {
    isActive: false,
    header: null,
    message: null,
    color: null,
  },
}

const AppContext = React.createContext(initialState)

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...initialState })

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Modal
        isModalActive={state.modal.isActive}
        isFullheight={state.modal.isFullheight}
        closeModal={() => dispatch({ type: "HIDE_MODAL" })}
        heading={state.modal.heading}
        modalHeaderClass={state.modal.headerClass}
        modalBackground={state.modal.background}
        hideCloseButton={state.modal.hideCloseButton}
        isCard={state.modal.isCard}
      >
        {state.modal.content}
      </Modal>
      <Toast
        isActive={state.toast.isActive}
        message={state.toast.message}
        color={state.toast.color}
      />
      {children}
      <Disclaimer
        isActive={state.disclaimer.isActive}
        message={state.disclaimer.message}
        header={state.disclaimer.header}
        color={state.disclaimer.color}
        closeDisclaimer={() => dispatch({ type: "HIDE_DISCLAIMER" })}
      />
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
